/* Keyframe animation for fade-in effect */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.gallery-container {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  padding: 20px 10px; /* Adds internal padding for spacing */
}


.project-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  justify-content: center;
  align-items: start;
  padding: 20px;
}

.project-card.animated {
  animation: fadeIn 0.5s ease-in-out forwards;
  opacity: 0; /* Start hidden */
}

.category-buttons {
  display: flex;
  justify-content: center; /* Center-align the buttons */
  margin-bottom: 20px;
  gap: 15px; /* Adds spacing between buttons */
  padding-top: 20px; /* Adds spacing from the navigation bar */
}

.category-buttons button {
  background-color: #007bff; /* Button background */
  border: none;
  color: white;
  padding: 10px 20px; /* Padding to make them look like buttons */
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px; /* Rounded corners for a button look */
  transition: all 0.3s ease;
}

.category-buttons button.active {
  background-color: #0056b3; /* Darker background for active state */
}

.category-buttons button:hover {
  background-color: #0056b3; /* Hover effect */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Slight shadow on hover */
}

.category-buttons button:focus {
  outline: none; /* Removes focus outline */
}
