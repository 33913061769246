/* Tabs */
.tabs {
  display: flex;
  justify-content: center;
  margin: 20px auto;
  padding-top: 20px;
}

.tab-button {
  background-color: ivory;
  color: royalblue;
  padding: 10px 20px;
  margin: 0 10px;
  border: 1px solid royalblue;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.tab-button.active {
  background-color: royalblue;
  color: white;
}

.tab-button:hover {
  background-color: lavender;
}

.note {
  
  justify-content: justify;
  margin: 20px auto;
  padding: 5px;
  /* padding-top: 20px; */
  border-radius: 5px;
  color: royalblue; 
  font-size: 16px;
  border: 1px dotted royalblue;
  width: 500px;
  vertical-align: middle;
}

.note span {padding: 1px;margin:1px;text-align: justify;line-height: 30px;}

/* Table container adjustments */
.table-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding: 5px;
  /*Aded on 3 Feb 2025*/
  max-height: 380px; /* Adjust height as needed */
  /* min-width:350px; */
  overflow-y:auto;
  /* scroll-behavior: auto; */
 /*Aded on 3 Feb 2025*/ 
}

  /*Aded on 3 Feb 2025*/
/* Sticky header for better UX */
.table-container thead {
  position: sticky;
  top: 0;
  background-color: #f4f4f4;
  z-index: 2;
}

  /*Aded on 3 Feb 2025*/
.responsive-table {
  /* display: flex; */
  width: 90%; /* Default width for most screens */
  max-width: 800px; /* Limit table size on larger screens */
  border: 1px solid royalblue;
  border-collapse: collapse;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  padding:1px;
  /*Aded on 3 Feb 2025*/
  border-collapse: collapse;
  /*Aded on 3 Feb 2025*/
}

.responsive-table th,
.responsive-table td {
  /* text-align: justify; */
  padding: 7px 11px;
  border: 1px dotted royalblue; /* Ensure visible borders */
  color: royalblue; /* Match text color with page theme */
}

.responsive-table th {
  text-align: center;
  background-color: royalblue;
  color: white; /* Contrast for header text */
}

.responsive-table tr:nth-of-type(even) {
  background-color: #f9f9f9; /* Light alternating row color */
}

.responsive-table tr:hover {
  background-color: lavender; /* Subtle hover effect */
}




/* Reduce width for Sr. No. and Duration, increase width for Service Title */
.sr-no {
  width: 10%;  /* Narrow */
  text-align: center;
  
  }

.service-title {
  width: 60%;  /* Wider */
  text-align: justify;
}

.duration {
  width: 20%;  /* Narrow */
  text-align: center;
}



/* Responsive behavior */
@media screen and (max-width: 768px) {
  .responsive-table {
    width: 100%; /* Full width for smaller screens */
  }

  .responsive-table td,
  .responsive-table th {
    font-size: 14px; /* Smaller font for mobile readability */
    padding: 10px;
  }
}


@media screen and (max-width: 480px) {
  .responsive-table {
    font-size: 12px; /* Further reduce font size for narrow screens */
    margin:2px;
  }

  .tabs {
    flex-wrap: wrap; /* Stack tabs for small screens */
  }

  .tab-button {
    flex: 1 1 calc(50% - 20px); /* Two tabs per row */
    margin: 5px;
  }
}
