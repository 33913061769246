 /* src/components/HomePage.css */

 .home-page {
  height: 90vh;
  padding: .25rem;
  box-sizing: border-box;
  background-color: lightgrey;
}

.content-container {
  display: flex;
  height: 100%; /* Full height of the page */
  margin:3px;
  
}

.left-box {
  flex: 1; /* Occupy two-thirds of the space */
  background-color: #fff;
  color: var(--text-color);
  background-color: var(--background-color);
  border: 1px dotted var(--text-color);
  color: var(--text-color);
  padding: 1.25rem 1rem 1.5rem; /* Adjust padding to reduce space above title */
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.left-box-title {
  font-size: 1.75rem; /* Adjust title font size */
  margin-bottom: 1rem; /* Space between title and list */
  text-align: left;
}

.skills-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.skills-list li {
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
  padding-left: 1.75rem;
  position: relative;
}

.skills-list li::before {
  content: '✔';
  position: absolute;
  left: 0;
  color: var(--text-color);
}

.right-box {
  flex: 2; /* Occupy one-third of the space */
  /* background-color: var(--text-color);
  color: #fff; */
  
  background-color: var(--text-color);
  color: var(--background-color);
  padding: 1.25rem 1.5rem 1.5rem; /* Adjust padding to reduce space above title */
  box-sizing: border-box;
  text-align: left; /* Align text to the left */
  font-size:large;

  border: 1px dotted #fff;
  overflow-y: auto; /* Enable vertical scrollbar */
   max-height: calc(100vh - 4rem); /* Adjust max-height to fit within viewport */
   margin-right:3px;
}

.right-box-title {
  font-size: 1.75rem; /* Reduce title font size */
  margin-bottom: .95rem; /* Space between title and list */
}


.technical-skills-list {
  list-style-type:none;
 /*  padding: 0;
  margin: 0; */
  display: grid;
grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
gap: 20px;
justify-items:stretch;
padding: 10px;
}

.technical-skills-list > li {
  margin-bottom: 1.15rem; /* Space between main list items */
  font-size: 1rem;
  font-size: .95rem;
  text-wrap:wrap;
  /* border: 1pt dashed yellow; */
  padding: 5px;
}

.technical-skills-list > li > ul {
  list-style-type:none;
  padding-left: .05rem; /* Indent for sub-list */
  margin-top: 0.2rem;

}

.technical-skills-list > li > ul > li {
  position: relative;
  padding:3px;
  padding-left: 20px; /* Space for custom symbol */
  /* font-style:italic; */
} 
.technical-skills-list > li > ul > li > strong {
  font-weight: bold;
  text-decoration:underline;
}
.technical-skills-list > li > ul > li::before {
  content: "✦"; /* Add your custom symbol here */
  position: absolute;
  left: 0;
  /* color: #2980b9; //Symbol color */
  font-size: 18px; /* Adjust size if needed */
}

@media (max-width: 768px) {
  .content-container {
    flex-direction: column;
  }

  .left-box {
    display: none; /* Hide left box in mobile mode */
  }

  .right-box {
    flex: 1;
    width: 100%;
    padding: 2rem; /* Increase padding for better visibility */
    max-height: none; /* Remove height constraint for mobile */
  }
}
