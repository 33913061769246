/* Contact Form Container */
.contact-container {
  text-align: justify;
  max-width: 400px;
  margin: 20px auto;
  padding: 20px;
  border: 1px dotted #4169E1; /* Royal Blue Border */
  border-radius: 10px;
  box-shadow: 2px 2px 10px rgba(65, 105, 225, 0.3);
}

/* Heading */
.contact-container h2 {
  text-align: center;
  color: #4169E1; /* Royal Blue Text */
  margin-bottom: 15px;
}

/* Input Fields */
.contact-container input,
.contact-container textarea {
  width: 75%;
  padding: 10px;
  margin: 8px 0;
  border: 1px solid #4169E1;
  border-radius: 5px;
  font-size: 16px;
}

/* Placeholder Text Color */
.contact-container input::placeholder,
.contact-container textarea::placeholder {
  color: #6A8FEA; /* Lighter Royal Blue */
}

/* Textarea */
.contact-container textarea {
  height: 100px;
  resize: none;
}

/* Submit Button */
.contact-container button {
  width: 100%;
  padding: 10px;
  background-color: #4169E1; /* Royal Blue */
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: 0.3s;
}

/* Button Hover Effect */
.contact-container button:hover {
  background-color: #3657C2; /* Darker Shade of Royal Blue */
}

/* Button Active Click Effect */
.contact-container button:active {
  background-color: #2B479D;
}

/* Responsive Design */
@media (max-width: 480px) {
  .contact-container {
      width: 90%;
  }
}
