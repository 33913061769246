/* NavBar.css */
.navbar {
  position: fixed; /* Fixes the navbar at the top */
    top: 0; /* Aligns the navbar to the top */
    width: 100%; /* Makes the navbar span the full width */
  background: white; /* Set background color to white */
  height: 45px; /* Updated height */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem; /* Adjusted font size */
  /* position: relative; */
  border-bottom: 1px solid #ccc; /* Bottom border */
  width: 100%; /* Ensure full width */
  /* border:1px solid red; */
  z-index: 1000;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%; /* Height adjusted to fit content */
  z-index: 1;
  width: 100%;
  max-width: 1500px;
  /* padding: 0 3px; */
  
}

.nav-logo {
  color: royalblue;
  color: crimson;
  /* color: burlywood; */
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem; /* Adjusted font size */
  display: flex;
  align-items: center;
}

.menu-icon {
  display: none;
  color: royalblue;
}

.nav-menu {
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin: 0;
}
ul { margin-right:0px;}
.nav-item {
  height: 45px; /* Updated height */
  width: 100%; /* Ensure menu items occupy full width */
  /* display:inline-block; */
width:auto;
padding:8px 16px;
float:left;
width:auto;
border:none;
display:block;
outline:0;
text-align:center
}

.nav-links {
  color: royalblue;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease;
  font-size: 1.8rem; /* Adjusted font size */
  letter-spacing: 1.5px;
  width: 100%; /* Ensure full width */
  box-sizing: border-box; /* Ensure padding doesn't add to width */

  
}

.nav-links:hover {
  color: blue;
  background-color: lightblue;
}

.nav-links-active {
  color: blue;
  background-color: lightskyblue;
}

.nav-menu.active {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 45px); /* Adjusted height calculation */
  position: absolute;
  top: 45px; /* Adjusted top position */
  left: 0;
  background: white; /* Set background color to white */
  margin: 0;
  border-bottom: 1px solid #ccc; /* Bottom border */
}

@media screen and (max-width: 960px) {
  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 45px; /* Updated height */
    z-index: 1;
    width: 100%;
    max-width: 1500px;
    padding: 0 24px;
  }

  .menu-icon {
    display: block;
    color: royalblue;
    font-size: 1.8rem;
    cursor: pointer;
  }

  .nav-menu {
    display: none;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 45px); /* Adjusted height calculation */
    position: absolute;
    top: 45px; /* Adjusted top position */
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    background: white; /* Set background color to white */
    margin: 0;
    border-bottom: 1px solid #ccc; /* Bottom border */
  }

  .nav-menu.active {
    display: flex;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-item {
    height: 45px; /* Maintain consistent height */
    width: 100%; /* Ensure menu items occupy full width */
  }

  .nav-links {
    text-align: center;
    padding: 10px 0; /* Reduced padding for smaller viewports */
    width: 100%; /* Ensure full width */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem; /* Adjusted font size */
  }

  .nav-links:hover,
  .nav-links-active {
    padding: 10px 0; /* Match reduced padding for hover and active states */
    width: 100%; /* Ensure full width for hover and active states */
  }
}
