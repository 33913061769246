.project-card {
    background-color: white;
    border: 1px solid #ddd;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: justify;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 300px;
    height: auto; /* Adjust height */
    max-height: 250px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .project-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
    border-color: #007bff;
  }
  
  .project-card h3 {
    margin: 0 0 10px 0;
    font-size: 16px;
    color: var(--text-color);
  }
  
  .project-card p {
    font-size: 16px;
    margin-bottom: 10px;
    color: #555;
  }
  
  .more-link {
    color: #007bff;
    cursor: pointer;
    font-weight: bold;
  }
  
  .project-tools {
    font-size: 14px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .project-tools ul {
    list-style-type: none;
    padding: 0;
  }
  
  .project-tools li {
    display: inline-block;
    margin-right: 8px;
    background-color: #f4f4f4;
    padding: 4px 8px;
    border-radius: 5px;
    font-size: 12px;
  }
  
  .project-card button {
    margin-top: auto;
    padding: 10px 15px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .project-card button:hover {
    background-color: #0056b3;
  }
  


  /* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal Content */
.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  text-align: justify;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

/* Modal Title */
.modal-content h2 {
  margin-top: 0;
  color: #007bff;
  text-align: center;
}

/* Modal Tools */
.tools {
  margin-top: 15px;
}

.tools ul {
  list-style-type: none;
  padding: 0;
}

.tools li {
  display: inline-block;
  background: #f4f4f4;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 5px;
  font-size: 14px;
}

/* Close Modal on Outside Click */
.modal-overlay:focus {
  outline: none;
}
